import React from 'react'
import '../assets/styles/Pricing.css'
import { ReactComponent as CheckMark } from '../assets/icons/check_circle.svg'

const COLORS = {
	GREEN: '#619E30',
	BROWN: '#A27D1D'
}

const PRICE_PLAN = [
	{
		title: 'Product Transfer (with Existing Lender)',
		price: '£80',
		priceSuffix: 'per application',
		description: 'Easily switch to a better deal with your current lender.',
		note: 'For just £80, we’ll provide detailed research and personalized recommendations to ensure a product transfer is the best option for you.',
		covers: [
			'Research from over 100+ lenders',
			'Ensure a product transfer is the best option for you',
			'Thousands of mortgage products',
			'Expert mortgage research and recommendations'
		]
	},
	{
		title: 'NHS, Armed Forces & Existing Client',
		price: '25% Off',
		priceSuffix: '',
		description: 'Special rates for our valued NHS staff, Armed Forces members, and loyal clients.',
		covers: [
			'First-time buyers',
			'Buy-to-let mortgages',
			'Re-mortgages (including product transfers)',
			'Limited company buy-to-let mortgages',
			'Access to over 100 lenders',
			'Thousands of mortgage products',
			'Expert mortgage research and recommendations'
		]
	},
	{
		title: 'Re-Mortgage with a New Lender',
		price: '£280',
		priceSuffix: 'per application',
		description: 'Secure better terms or release equity by switching to a new lender.',
		covers: [
			'Capital raising options',
			'Transfer of equity support',
			'Access to over 100 lenders',
			'Thousands of mortgage products',
			'Expert mortgage research and recommendations'
		]
	},
	{
		title: 'Purchase Mortgages',
		price: '£530',
		priceSuffix: 'per application',
		description: 'Whether you’re a first-time buyer or investing, we’re here to guide you every step of the way.',
		covers: [
			'First-time buyers',
			'Buy-to-let mortgages',
			'Limited company buy-to-let mortgages',
			'Access to over 100 lenders',
			'Thousands of mortgage products',
			'Expert mortgage research and recommendations',
			'Full support throughout the entire process'
		]
	}
]

const Cover = ({ cover, color }) => (
	<li>
		<CheckMark fill={color} /> <span>{cover}</span>
	</li>
)

const PricingCard = ({ plan }) => {
	const { title, price, priceSuffix, description, note, covers } = plan
	const free = price === 'FREE'
	const cardClass = free ? 'secondary' : 'primary'
	const cardOutlineColor = free ? COLORS.GREEN : COLORS.BROWN
	return (
		<div className={`pricing__cards-container__card pricing__cards-container__card--${cardClass}`}>
			<div className="pricing__cards-container__card__sections">
				<h4 className="pricing__cards-container__card__section__heading">{title}</h4>
				<div className="pricing__cards-container__card__section">
					<h3 className={`pricing__cards-container__card__section__price--${cardClass}`}>
						{price}
						<span className="pricing__cards-container__card__section__price-suffix">{priceSuffix}</span>
					</h3>
					<p className="pricing__cards-container__card__section__description">{description}</p>
					{note && <p className="pricing__cards-container__card__section__note">{note}</p>}
					<div className="pricing__cards-container__card__cover">
						{covers.map((cover, index) => (
							<Cover cover={cover} color={cardOutlineColor} key={index} />
						))}
					</div>
				</div>
			</div>
			<button className={cardClass} onClick={() => (window.location.href = 'tel:01483339247')}>
				Get Started
			</button>
		</div>
	)
}

export const Pricing = () => {
	return (
		<section id="pricing" className="pricing">
			<div className="pricing__heading">
				<h3>Simple Pricing Structure</h3>
				<h4>No Hidden Charges!</h4>
				<p>Transparent and fair pricing for your peace of mind. No surprises, just straightforward solutions tailored to you.</p>
			</div>
			<div className="pricing__cards-container">
				{PRICE_PLAN.map((plan, index) => (
					<PricingCard plan={plan} key={index} />
				))}
			</div>
			<h4 className="pricing__note">Your Home may be repossessed if you do not keep up repayments on your mortgage.</h4>
		</section>
	)
}
